	/*
  	Flaticon icon font: Flaticon
  	Creation date: 17/08/2016 11:43
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("flaticon.eot");
  src: url("flaticon.eot?#iefix") format("embedded-opentype"),
       url("flaticon.woff") format("woff"),
       url("flaticon.ttf") format("truetype"),
       url("flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-24-hours-support:before { content: "\f100"; }
.flaticon-arrows:before { content: "\f101"; }
.flaticon-atom:before { content: "\f102"; }
.flaticon-bars:before { content: "\f103"; }
.flaticon-check-square:before { content: "\f104"; }
.flaticon-checked:before { content: "\f105"; }
.flaticon-commerce:before { content: "\f106"; }
.flaticon-diamond:before { content: "\f107"; }
.flaticon-dollar-symbol:before { content: "\f108"; }
.flaticon-four-grid-layout-design-interface-symbol:before { content: "\f109"; }
.flaticon-home:before { content: "\f10a"; }
.flaticon-leather-derby-shoe:before { content: "\f10b"; }
.flaticon-like:before { content: "\f10c"; }
.flaticon-monitor:before { content: "\f10d"; }
.flaticon-multiple-computers-connected:before { content: "\f10e"; }
.flaticon-photo-camera:before { content: "\f10f"; }
.flaticon-return-of-investment:before { content: "\f110"; }
.flaticon-right-arrow-circular-button:before { content: "\f111"; }
.flaticon-search:before { content: "\f112"; }
.flaticon-shield:before { content: "\f113"; }
.flaticon-soccer-ball:before { content: "\f114"; }
.flaticon-squares-gallery-grid-layout-interface-symbol:before { content: "\f115"; }
.flaticon-table-lamp:before { content: "\f116"; }
.flaticon-technology:before { content: "\f117"; }
.flaticon-time:before { content: "\f118"; }
.flaticon-timer:before { content: "\f119"; }
.flaticon-transport:before { content: "\f11a"; }
.flaticon-umbrella:before { content: "\f11b"; }
.flaticon-user-outline:before { content: "\f11c"; }